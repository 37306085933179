import React from "react";

// reactstrap components
import {
  Nav,
  NavLink,
  NavItem
} from "reactstrap";

// index components
import Equipments from "./Equipments";
import Chemicals from "./Chemicals";
import Services from "./Services";

function ProductDetails() {
  const path = window.location.href;
  const [activeTab, setActiveTab] = React.useState(path.includes('chemicals') ? 2 : 1);

  return (
    <>
      <div className="products-and-services-details">
        <Nav tabs className="w-100">
          <NavItem>
            <NavLink
              className={activeTab === 1 && "active"}
              onClick={() => {
                setActiveTab(1);
                window.history.replaceState(null, "New Page Title", "/products-and-services/equipments");
              }}
            >
              Equipments
              {activeTab === 1 && <div style={{ width: "90px", borderBottom: "2px solid #4C84C3", borderWidth: "medium" }}
                className="mx-auto active-div-tab" />}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 2 && "active"}
              onClick={() => {
                setActiveTab(2);
                window.history.replaceState(null, "New Page Title", "/products-and-services/chemicals");
              }}
            >
              Chemicals
              {activeTab === 2 && <div style={{ width: "80px", borderBottom: "2px solid #4C84C3", borderWidth: "medium" }}
                className="mx-auto active-div-tab" />}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 3 && "active"}
              onClick={() => {
                setActiveTab(3);
                window.history.replaceState(null, "New Page Title", "/products-and-services/services");
              }}
            >
              Services
              {activeTab === 3 && <div style={{ width: "65px", borderBottom: "2px solid #4C84C3", borderWidth: "medium" }}
                className="mx-auto active-div-tab" />}
            </NavLink>
          </NavItem>
        </Nav>
        {activeTab === 1 && <Equipments />}
        {activeTab === 2 && <Chemicals />}
        {activeTab === 3 && <Services />}
      </div>
    </>
  );
}

export default ProductDetails;
