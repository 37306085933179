import React from "react";

import {
  Container
} from "reactstrap";

function AboutUsHistory() {
  return (
    <>
      <div className="section about-us-history">
        <Container>
          <div className="content">
            <h3 className="font-weight-bold font-light-blue-color mb-4">Our History</h3>
            <p className="font-weight-normal text-medium" style={{ textJustify: "inter-words" }}>
              OUR COMPANY was established in 2003 as "RST INDUSTRIAL SOLUTIONS", as a formulator of specialty chemicals for water, wastewater treatment, maintenance
              and other industrial applications. We also represent reputable European and Asian companies for the supply and delivery of parts and equipment used in
              various industries, particularly in the field of filtration.
              <br /><br />
              Moreover, our services extend beyond supply and delivery as we commit to providing excellent after-sales service to our clients' satisfaction.
              <br /><br />
              Roentgen Separation Technologie, (RST) Inc. is spearheaded by 3 key people and it grew to date with a total of more than 40 employees. Comprises of administrative 
              officers and managers, project managers, technical and sales engineers, technical and production staff. Company expanded its operations from Luzon and Visayas 
              down to  Cagayan de Oro and Davao in the Mindanao Region.
            </p>
          </div>
        </Container>
      </div>
    </>
  );
}

export default AboutUsHistory;
