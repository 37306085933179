import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// img
import litoIcon from "./../../assets/img/roentgen/about_us/Lito_B._Sollegue_ID_PIC.png";

function AboutUsLeadership() {
  return (
    <>
      <div className="section about-us-leadership">
        <Container>
          <div className="content">
            <Row>
              <Col xs={12} sm={12} md={6} className="d-flex justify-content-center align-items-center">
                <img src={litoIcon} alt="Lito" width={380} className="leadership-img mb-3 mx-auto" />
              </Col>
              <Col xs={12} sm={12} md={6} className="my-auto">
                <h4 className="font-weight-bold font-light-blue-color">Leadership</h4>
                <h3 className="font-weight-bold font-light-yellow-color mt-2 mb-3">Lito B. Sollegue, President & CEO</h3>
                <p className="font-weight-normal text-medium">
                  Lito B. Sollegue was born and raised in Bicol. He graduated with a degree of Bachelor of Science in Chemical Engineering from Mapua Institute of
                  Technology Intramuros Manila year 1994. Lito has always been dedicated, passionate, and committed to his goals. 
                <br /><br />
                  After graduating, Lito brings his expertise and worked internationally to pursue his passion.
                <br /><br />
                  He began to establish RST Industrial Solutions year 2003.
                <br /><br />
                  Lito is now focused full-time on managing and directing his employees toward the company’s goals and objectives. He also travels locally
                  and internationally for the sustainability of the company and communicates with delegates from other companies.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default AboutUsLeadership;
