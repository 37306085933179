import React from "react";

import {
  Container
} from "reactstrap";

function AboutUsHistory() {
  return (
    <>
      <div className="section about-us-history">
        <Container>
          <div className="content">
            <h3 className="font-weight-bold font-light-blue-color mb-4">Where We Are Now</h3>
            <p className="font-weight-normal text-medium">
              In line with our dedication to provide our customers with first-rate service, 
              we incorporated RST Industrial Solutions and now under the new name ROENTGEN SEPARATION TECHNOLOGIE, (RST) INC.
              <br /><br />
              As the requirements and evolving technologies unfold in this industry, RSTI recognizes that innovation and creativity are the keys to sustaining
              its competitiveness. This leads us to the formation of our Engineering Services Division which includes a group of engineers and technical people
              with expertise in waters and wastewater treatment design, plant construction, environmental management, and production control.
              <br /><br />
              RSTI is aimed at sustaining a competitive existence, with the end in view of emerging in the tier of industry leaders within the medium term.
              Rest assured that you can get high-quality service, prompt deliveries. first-rate materials and practical solutions from honest and competent
              professionals as we continuously improve our products and services to help our customers achieve greater decency and productivity.
            </p>
          </div>
        </Container>
      </div>
    </>
  );
}

export default AboutUsHistory;
