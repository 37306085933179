import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Button
} from "reactstrap";

// components
import PurificationSystems from "./PurificationSystems";
import Accessories from "./Accessories";
import DewateringSystems from "./DewateringSystems";
import Screen from "./Screen";
import Pumps from "./Pumps";

function Equipments() {
  const [activeTab, setActiveTab] = React.useState(1);

  return (
    <>
      <div className="section products-and-services-equipments">
        <Container fluid={true} style={{ padding: '0 80px'}}>
          <div className="content">
          <Row>
              <Col xs={12} sm={12} md={12} lg={4} className="mb-5">
                <Nav className="equipments-vertical-nav shadow" vertical>
                  <NavItem>
                    <NavLink className={activeTab === 1 && "active"}
                      onClick={() => setActiveTab(1)}>
                      Water Purification Systems
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink className={activeTab === 2 && "active"}
                      onClick={() => setActiveTab(2)}>
                      Accessories
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink className={activeTab === 3 && "active"}
                      onClick={() => setActiveTab(3)}>
                      Dewatering System
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink className={activeTab === 4 && "active"}
                      onClick={() => setActiveTab(4)}>
                      Screen
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink className={activeTab === 5 && "active"}
                      onClick={() => setActiveTab(5)}>
                      Pumps
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                {activeTab === 1 && <PurificationSystems />}
                {activeTab === 2 && <Accessories />}
                {activeTab === 3 && <DewateringSystems />}
                {activeTab === 4 && <Screen />}
                {activeTab === 5 && <Pumps />}
              </Col>
            </Row>
          </div>
        </Container>
        <Button className='inquire-button font-weight-bold' href="/contact">
          <i class="fa fa-phone mr-1" />
          <span><u>Inquire now</u></span>
        </Button>
      </div>
    </>
  );
}

export default Equipments;
