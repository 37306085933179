import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// img
import section2bg from "assets/img/roentgen/careers/section_2_img.png";

function SectionTwo() {
  return (
    <>
      <div className="section-two">
        <Container>
          <div className="content">
            <Row className="large-screen-row align-items-center">
              <Col md={12} lg={6}>
                <div className="mx-5">
                  <h3 className="font-light-blue-color" style={{ fontWeight: 600 }}>Leadership</h3>
                  <p className="font-weight-normal mt-2">
                    Employees are an important part of your company's ability to grow and thrive, but a leader is in charge of the organization's orientation and
                    direction since he or she establishes the vision and mission that others must follow. As a result, a good leadership style influences and
                    secures the success and advancement of both the organization and its personnel.
                    <br /><br />
                    Our leader will guide the organization and its employees toward good progress; nevertheless, he will not just issue orders or directives
                    without providing context. Instead, he uses effective communication and motivational techniques to motivate his employees to take action.
                    He is a leader who motivates and inspires his colleagues, solicits feedback from them, keeps them informed, provides timely and specific
                    feedback on job performance, ensures that training needs are met, and holds them accountable.
                  </p>
                </div>
              </Col>
              <Col>
                <img src={section2bg} className="main-img" alt="section2-bg" />
              </Col>
            </Row>
            
            <Row className="small-screen-row d-none align-items-center">
              <Col md={12} lg={6} className="small-screen-img-column">
                <img src={section2bg} className="main-img" alt="section2-bg" />
              </Col>
              <Col>
                <div className="mx-5">
                  <h3 className="font-light-blue-color" style={{ fontWeight: 600 }}>Leadership</h3>
                  <p className="font-weight-normal mt-2">
                    Employees are an important part of your company's ability to grow and thrive, but a leader is in charge of the organization's orientation and
                    direction since he or she establishes the vision and mission that others must follow. As a result, a good leadership style influences and
                    secures the success and advancement of both the organization and its personnel.
                    <br /><br />
                    Our leader will guide the organization and its employees toward good progress; nevertheless, he will not just issue orders or directives
                    without providing context. Instead, he uses effective communication and motivational techniques to motivate his employees to take action.
                    He is a leader who motivates and inspires his colleagues, solicits feedback from them, keeps them informed, provides timely and specific
                    feedback on job performance, ensures that training needs are met, and holds them accountable.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default SectionTwo;
