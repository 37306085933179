import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

// index sections
import ProductsAndServicesIntro from "./ProductsAndServicesIntro";
import ProductDetails from "./ProductDetails";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  return (
    <>
      <IndexNavbar isResponsive={false} />
      <ProductsAndServicesIntro />
      <div className="main">
        <ProductDetails />
      </div>
      <Footer />
    </>
  );
}

export default LandingPage;
