import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// img
import section5bg from "assets/img/roentgen/careers/section_5_img.png";

function SectionFive() {
  return (
    <>
      <div className="section-five">
        <Container>
          <div className="content">
            <Row className="align-items-center">
              <Col>
                <img src={section5bg} className="main-img" alt="section5-bg" />
              </Col>
              <Col md={12} lg={6}>
                <div className="mx-5">
                  <h3 className="font-light-blue-color text-right" style={{ fontWeight: 600 }}>Reward System and Benefits</h3>
                  <p className="font-weight-normal mt-2">
                    Our comprehensive benefits package is well-regarded, and it is made to measure the basic and our employees' demands for life-changing perks.
                    As market conditions change, the Company evaluates its benefits programs on a regular basis to ensure that workers receive the benefits they want
                    and have a variety of options to address individual and family challenges and promote healthy lifestyles.
                    <br /><br />
                  </p>
                  <p className="font-weight-normal">
                    ● Base salary<br />
                    ● Annual Incentives
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default SectionFive;
