import React from "react";

import {
  Container
} from "reactstrap";

function AboutUsWhereWeAim() {
  return (
    <>
      <div className="section about-us-history">
        <Container>
          <div className="content">
            <h3 className="font-weight-bold font-light-blue-color mb-4">What We Aim</h3>
            <p className="font-weight-normal text-medium">
              RSTI is aimed at sustaining a competitive existence, with the end in view of emerging in the tier of industry leaders within the medium term.
              Rest assured that you can get high-quality series, prompt deliveries. first-rate materials and practical solutions from honest and competent
              professionals as we continuously improve our products and services to help our customers achieve greater decency and productivity.
            </p>
          </div>
        </Container>
      </div>
    </>
  );
}

export default AboutUsWhereWeAim;
