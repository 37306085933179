import React from "react";

function ClientsIntro() {
  return (
    <>
      <div className="section clients-intro d-flex justify-content-center align-items-center">
        <div className="overlay">
          <h1 className="font-weight-bold m-0">Business Partners</h1>
        </div>
      </div>
    </>
  );
}

export default ClientsIntro;
