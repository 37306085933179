// reactstrap components
import {
  Container,
  Card,
  Row,
  Col
} from "reactstrap";

// img
import alaskaLogo from "./../../assets/img/roentgen/clients/Alaska.png";
import carmenCopperLogo from "./../../assets/img/roentgen/clients/Carmen Copper Corporation.png";
import victoriasLogo from "./../../assets/img/roentgen/clients/Victorias Milling Company.png";
import manilaWatersLogo from "./../../assets/img/roentgen/clients/manila_waters.png";
import robinaLogo from "./../../assets/img/roentgen/clients/Universal Robina.png";
import evergreenLogo from "./../../assets/img/roentgen/clients/evergreen.png";
import eyeLogo from "./../../assets/img/roentgen/clients/eye.png";
import ibidenLogo from "./../../assets/img/roentgen/clients/ibiden.png";
import philexMiningLogo from "./../../assets/img/roentgen/clients/PBI.png";
import pbiLogo from "./../../assets/img/roentgen/clients/philex_mining.png";
import primeMeridianLogo from "./../../assets/img/roentgen/clients/prime_meridian.png";
import soccLogo from "./../../assets/img/roentgen/clients/socc.png";
import unionGalvasteelLogo from "./../../assets/img/roentgen/clients/union_galvasteel.png";
import vtviLogo from "./../../assets/img/roentgen/clients/vtvi.png";
import wukongSgLogo from "./../../assets/img/roentgen/clients/wukong_sg.png";
import mayniladLogo from "./../../assets/img/roentgen/clients/Maynilad.png";
import philsagaLogo from "./../../assets/img/roentgen/clients/philsaga_mining.png";

function Clients() {
  const topClients = [
    {
      image: mayniladLogo,
      title: "Maynilad Water Company Inc.",
      subTitle: "" 
    },
    {
      image: alaskaLogo,
      title: "Alaska Milk Corporation",
      subTitle: "" 
    },
    {
      image: robinaLogo,
      title: "Universal Robina Corporation",
      subTitle: "" 
    },
    {
      image: victoriasLogo,
      title: "Victoria Milling Company",
      subTitle: "" 
    },
    {
      image: carmenCopperLogo,
      title: "Carmen Copper Corporation",
      subTitle: "" 
    },
    {
      image: philsagaLogo,
      title: "Philsaga Mining Corporation",
      subTitle: "" 
    },
    {
      image: manilaWatersLogo,
      title: "Manila Waters Company Inc.",
      subTitle: "" 
    },
  ];

  const smallClients = [
    {
      image: pbiLogo,
      title: "Philippine Batteries Inc.",
      subTitle: "" 
    },
    {
      image: evergreenLogo,
      title: "Evergreen Environmental",
      subTitle: "" 
    },
    {
      image: ibidenLogo,
      title: "Ibiden Philippines Inc.",
      subTitle: "" 
    },
    {
      image: philexMiningLogo,
      title: "Philex Mining Corporation",
      subTitle: "" 
    },
    {
      image: vtviLogo,
      title: "TVI Resources Development",
      subTitle: "" 
    },
    {
      image: eyeLogo,
      title: "EYE",
      subTitle: "" 
    },
    {
      image: unionGalvasteelLogo,
      title: "Union Galvasteel Corporation",
      subTitle: "" 
    },
    {
      image: wukongSgLogo,
      title: "Wu Kong Pte Ltd",
      subTitle: "" 
    },
    {
      image: primeMeridianLogo,
      title: "Prime Meridian Power",
      subTitle: "" 
    },
    {
      image: soccLogo,
      title: "Sakamoto Orient Chemicals Corporation",
      subTitle: "" 
    },
  ];

  const topClientCards = (item) => {
    return <Col sm={12} md={6} key={item.title}>
        <Card body className="py-5 my-5">
          <Row className="d-flex align-items-center justify-content-center">
            <img src={item.image} alt={`${item.title}-icon`} style={{ height: "100px", width: "auto"}} />
          </Row>
        </Card>
      </Col>;
  }

  const smallClientCards = (item) => {
    return <Col sm={12} md={4} key={item.title}>
        <Card body className="py-5 my-5">
          <Row className="d-flex align-items-center justify-content-center  px-3">
            <img src={item.image} alt={`${item.title}-icon`} style={{ height: "100px", width: "auto"}} />
          </Row>
        </Card>
      </Col>;
  }

  return (
    <>
      <div className="section clients-clients">
        <Container>
          <div className="content">
            <Row className="justify-content-center">
              {topClients.map(item => {
                return topClientCards(item)
              })}
            </Row>
            <Row className="justify-content-center">
              {smallClients.map(item => {
                return smallClientCards(item)
              })}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Clients;
