/*eslint-disable*/
import React from "react";

// reactstrap components
import { 
  Button,
  Container } from "reactstrap";

// core components

function IndexHeader() {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/img/roentgen/landing_bg_2.jpg").default + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <h1 className="presentation-title">Quality...<br/>Excellence.<br/>Progress.</h1>
            </div>
            <h2 className="presentation-subtitle">
              <Button
                className="btn-round px-5"
                color="warning"
                href="/products-and-services"
              >
                Explore
              </Button>
            </h2>
          </Container>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;
