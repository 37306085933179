import React from "react";

// react components
import {
  Row,
  Col
} from "reactstrap";

// img
import chemEngImg from "assets/img/roentgen/careers/chem_img.png";
import truckDriverImg from "assets/img/roentgen/careers/truck_driver_img.png";
import chemistImg from "assets/img/roentgen/careers/chemist_img.png";
import warehouseAssistantImg from "assets/img/roentgen/careers/chem_eng_img.png";

function Jobs() {
  const jobs = [
    {
      title: "Chemical Engineer",
      description: "Creates engineering solutions to issues relating to the creation and utilization of chemicals. designs machinery for chemical producers and plans and evaluates production processes.",
      bgImg: chemEngImg,
      key: 1
    },
    {
      title: "Chemist",
      description: "Conduct tests in laboratories to evaluate chemicals and generate new commodities to enhance what are already on the market.",
      bgImg: chemistImg,
      key: 2
    },
    {
      title: "Driver",
      description: "Handles tasks related to receiving, storing, inventorying, and issuing items and supplies for a central storage warehouse that conducts distribution operations and has a significant amount of materials and supplies.",
      bgImg: truckDriverImg,
      key: 3
    },
    {
      title: "Warehouse Assistant",
      description: "Handles tasks related to receiving, storing, inventorying, and issuing items and supplies for a central storage warehouse that conducts distribution operations and has a significant amount of materials and supplies.",
      bgImg: warehouseAssistantImg,
      key: 4
    }
  ];

  return (
    <>
      <div className="jobs">
        <div className="content">
          <Row>
            {jobs.map(job => {
              return <Col sm={12} md={6} lg={6} xl={3} className="p-0 mx-auto">
              <img src={job.bgImg} alt="job-img" className="job-item-img h-100 w-100" />
                <div className="job-item text-center  px-lg-5">
                  <h2 className="font-weight-bold text-light">{job.title}</h2>
                  <p className="text-center">{job.description}</p>
                </div>
              </Col>
            })}
          </Row>
        </div>
      </div>
    </>
  );
}

export default Jobs;
