import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// img
import section1bg from "assets/img/roentgen/careers/section_1_img.png";
import polkaBg from "assets/img/roentgen/careers/polkadots.png";

function SectionOne() {
  return (
    <>
      <div className="section-one">
        <Container>
          <div className="content">
            <Row className="large-screen-row">
              <Col>
                <img src={section1bg} className="main-img" alt="section1-bg" />
              </Col>
              <Col md={12} lg={6}>
                <h1 className="font-light-yellow-color font-weight-bold title">Why choose us?</h1>
                <img src={polkaBg} className="polka-img" alt="section1-polkadots" />
              </Col>
            </Row>
            
            <Row className="small-screen-row d-none align-items-center">
              <Col>
                <h1 className="font-light-yellow-color font-weight-bold title">Why choose us?</h1>
              </Col>
              <Col md={12} lg={6} className="small-screen-img-column">
                <img src={section1bg} className="main-img" alt="section1-bg" />
                <img src={polkaBg} className="polka-img" alt="section1-polkadots" />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default SectionOne;
