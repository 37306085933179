import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Card,
    CardBody,
    CardImg,
    CardTitle,
    CardText,
    Carousel,
    CarouselIndicators,
    CarouselItem,
    CarouselControl
} from "reactstrap";

// img
import news_img from "assets/img/roentgen/home/news/WASTE WATER TREATMENT CHEMICAL HOMEPAGE.jpg";
import news_img_2 from "assets/img/roentgen/home/news/WASTE WATER TREATMENT PLANT.jpg";

const productsOne = () => {
    return  <Row>
        <Col xs={12} sm={6}>
            <Card className="shadow-sm">
                <CardImg width="100%" src={news_img} alt="News image" style={{ height: '250px' }} />
                <CardBody>
                <CardTitle tag="h6">
                    Water and Wastewater Treatment Chemicals 
                </CardTitle>
                <CardText>
                    Specialized chemicals that act as sanitizing, disinfecting, and purifying agents for water and wastewater.
                    <br /><u><a href={"/products-and-services/chemicals"} className="font-light-yellow-color text-underline">Learn more...</a></u>
                </CardText>
                </CardBody>
            </Card>
        </Col>
        <Col xs={12} sm={6}>
            <Card className="shadow-sm">
                <CardImg width="100%" src={news_img_2} alt="News Image 2" style={{ height: '250px' }}/>
                <CardBody>
                <CardTitle tag="h6">
                    Water and Wastewater Treatment Plant 
                </CardTitle>
                <CardText>
                    Where water, wastewater, and pollutants are treated using a variety of different processes.
                    <br /><u><a href={"/products-and-services/equipments"} className="font-light-yellow-color text-underline">Learn more...</a></u>
                </CardText>
                </CardBody>
            </Card>
        </Col>
    </Row>;
}

const dummyItems = [
    {
        altText: 'Latest News Slides 1',
        caption: 'Latest News Slides 1',
        key: 1,
        src: productsOne()
    }
];

function LandingLatestNewsCarousel() {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);

    const itemLength = dummyItems.length - 1
  
    const previousButton = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ?
            itemLength : activeIndex - 1;
        setActiveIndex(nextIndex);
    }
  
    const nextButton = () => {
        if (animating) return;
        const nextIndex = activeIndex === itemLength ?
            0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }
  
    const carouselItemData = dummyItems.map((item) => {
        return (
            <CarouselItem
                key={item.altText}
                onExited={() => setAnimating(false)}
                onExiting={() => setAnimating(true)}
            >
                {item.src}
            </CarouselItem>
        );
    });

    return (
        <>
            <Carousel previous={previousButton} next={nextButton}
                activeIndex={activeIndex}>
                <CarouselIndicators items={dummyItems}
                    activeIndex={activeIndex}
                    onClickHandler={(newIndex) => {
                        if (animating) return;
                        setActiveIndex(newIndex);
                    }} />
                {carouselItemData}
                <CarouselControl directionText="Prev"
                    direction="prev" onClickHandler={previousButton} />
                <CarouselControl directionText="Next"
                    direction="next" onClickHandler={nextButton} />
            </Carousel>
        </>
    );
}

export default LandingLatestNewsCarousel;