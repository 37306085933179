import React from "react";

import {
  Container,
  Button
} from "reactstrap";

// img
import companyProfileImg from "./../../assets/img/roentgen/about_us/rsti-company-profile.jpg";

// pdf
import chargeSample from "./../../COMPANY PROFILE FINAL.pdf";

function AboutUsCompanyProfile() {
  return (
    <>
      <div className="section about-us-company-profile">
        <Container>
          <div className="content  text-center">
            <div className="font-weight-normal text-medium">
              <img src={companyProfileImg} alt="company-profile" className="company-profile-img" />
            </div>
            <Button
              className="btn-round px-4 mt-4 "
              color="warning"
              href={chargeSample}
              target="_blank"
            >
              Download a Copy
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
}

export default AboutUsCompanyProfile;
