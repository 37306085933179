/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Col, Container } from "reactstrap";

// img
import roentgenLogo from "./../../assets/img/roentgen/roentgen_white_long.png";

function DemoFooter() {
  return (
    <footer className="footer">
      <Container>
        <Row className='pt-5'>
          <Col>
            <img src={roentgenLogo} alt="Roentgen Icon" width={215} className="roentgen-img" />
          </Col>
          <Col className='d-flex align-items-center justify-content-end'>
            <div class="social">
              <a class="fa fa-linkedin" title="" target="_blank" href="https://www.linkedin.com/company/roentgen-separation-technologie-rst-inc"></a>
              <a class="fa fa-facebook" title="" target="_blank" href="https://www.facebook.com/roentgenseparation/"></a>
              <a class="fa fa-twitter" title="" target="_blank" href="https://www.twitter.com"></a>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="credits mx-auto">
            <span className="copyright">
              All Rights Reserved © {new Date().getFullYear()}, Roentgen Separation Technologie, (RST) Inc.
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
