import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import LandingPageFooter from "components/Footers/LandingPageFooter.js";

// index sections
import LandingIntro from "./LandingIntro";
import LandingLatestNews from "./LandingLatestNews";
import LandingTechnologyPartners from "./LandingTechnologyPartners";
import LandingTrustedCompanies from "./LandingTrustedCompanies";

function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  return (
    <>
      <IndexNavbar isResponsive={true} />
      <IndexHeader />
      <div className="main">
        <LandingIntro />
        <LandingLatestNews />
        <LandingTechnologyPartners />
        <LandingTrustedCompanies />
        <LandingPageFooter />
      </div>
    </>
  );
}

export default Index;
