import React from "react";

// react components
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";

// img logos
import jarTestingIcon from "assets/img/roentgen/products_and_services/jar_testing.png";
import pilotTestingIcon from "assets/img/roentgen/products_and_services/pilot_testing.png";
import waterTreatmentIcon from "assets/img/roentgen/products_and_services/water_treatment.png";
import testFiltrationIcon from "assets/img/roentgen/products_and_services/test_filtration.png";
import operationMaintenanceIcon from "assets/img/roentgen/products_and_services/operation_maintenance.png";
import installationCommisioningIcon from "assets/img/roentgen/products_and_services/installation_commisioning.png";

function Services() {
  const services = [
    {
      title: 'Jar Testing',
      description: 'A method used in laboratories to imitate coagulation or flocculation using various chemical dosages The procedure\'s goal is to calculate the minimal coagulant dose necessary to meet specific water quality objectives.',
      serviceImg: jarTestingIcon,
      key: 1
    },
    {
      title: 'Water Treatment System',
      description: 'Process that enhances water quality to make it suitable for a certain end purpose. Drinking water, industrial water supply, irrigation, maintaining river flow, water recreation, or a variety of other purposes, including environmentally appropriate disposal, could be the ultimate use.',
      serviceImg: waterTreatmentIcon,
      key: 2
    },
    {
      title: 'Test Filtration',
      description: 'The method of removing particulates from liquids. To filter out solids from a liquid is the aim of liquid filtration testing.',
      serviceImg: testFiltrationIcon,
      key: 3
    },
    {
      title: 'Installation and Commissioning',
      description: 'Cooling Tower, Filter Press, WTP, WWTP',
      serviceImg: installationCommisioningIcon,
      key: 4
    },
    {
      title: 'Pilot Testing',
      description: 'Evaluation of wastewater treatment methods, tools, or both over a brief period of time and at a small scale in order to establish the design parameters for implementation at full scale.',
      serviceImg: pilotTestingIcon,
      key: 5
    },
    {
      title: 'Operation & Maintenance',
      description: 'WTP, WWTP',
      serviceImg: operationMaintenanceIcon,
      key: 6
    }
  ];

  return (
    <>
      <div className="section products-and-services-services">
        <Container>
          <div className="content">
            <Row>
              {services.map(service => {
                return <Col md={12} xl={6} className="my-3 d-flex align-items-start">
                  <img src={service.serviceImg} width="140" alt="Jar Testing" className="float-left" />
                  <div className="h-100">
                    <h4 style={{ margin: '15px 0 0 ', fontWeight: 500 }} className="font-light-blue-color">{service.title}</h4>
                    <p className="mt-1 font-weight-normal">{service.description}</p>
                  </div>
                </Col>
              })}
            </Row>
          </div>
        </Container>
        <Button className='inquire-button font-weight-bold' href="/contact">
          <i class="fa fa-phone mr-1" />
          <span><u>Inquire now</u></span>
        </Button>
      </div>
    </>
  );
}

export default Services;
