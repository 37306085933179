import React from "react";

// reactstrap components
import {
  Container
} from "reactstrap";

// components
import LandingTrustedCompaniesCarousel from './LandingTrustedCompaniesCarousel';

function LandingTrustedCompanies() {
  return (
    <>
      <div className="section landing-trusted-companies">
        <Container>
            <div className="text-center">
              <p className="font-light-blue-color font-weight-normal">Business Partners</p>
              <h4 className="mt-0 font-weight-bold">Trusted by these companies</h4>
              <div style={{ width: "50px", height: "10px", borderBottom: "3px solid", borderWidth: "medium" }}
                className="font-light-blue-color mx-auto mb-3 mt-1 active-div-tab" />
            </div>
            <LandingTrustedCompaniesCarousel />
        </Container>
      </div>
    </>
  );
}

export default LandingTrustedCompanies;
