import React from "react";

// reactstrap components
import {
    Carousel,
    CarouselIndicators,
    CarouselItem,
    CarouselControl
} from "reactstrap";

// img
import grouped_1 from "assets/img/roentgen/home/carousel/feat_clients.png";
import grouped_2 from "assets/img/roentgen/home/carousel/feat_clients_2.png";

const dummyItems = [
    {
        altText: 'Company Slides 1',
        caption: 'Company Slides 1',
        key: 1,
        src: grouped_1
    },
    {
        altText: 'Company Slides 2',
        caption: 'Company Slides 2',
        key: 2,
        src: grouped_2
    }
];

function LandingTrustedCompaniesCarousel() {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);

    const itemLength = dummyItems.length - 1
  
    const previousButton = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ?
            itemLength : activeIndex - 1;
        setActiveIndex(nextIndex);
    }
  
    const nextButton = () => {
        if (animating) return;
        const nextIndex = activeIndex === itemLength ?
            0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }
  
    const carouselItemData = dummyItems.map((item) => {
        return (
            <CarouselItem
                key={item.src}
                onExited={() => setAnimating(false)}
                onExiting={() => setAnimating(true)}
            >
                <img src={item.src} alt={item.altText} width={100} />
            </CarouselItem>
        );
    });

    return (
        <>
            <Carousel previous={previousButton} next={nextButton}
                activeIndex={activeIndex}>
                <CarouselIndicators items={dummyItems}
                    activeIndex={activeIndex}
                    onClickHandler={(newIndex) => {
                        if (animating) return;
                        setActiveIndex(newIndex);
                    }} />
                {carouselItemData}
                <CarouselControl directionText="Prev"
                    direction="prev" onClickHandler={previousButton} />
                <CarouselControl directionText="Next"
                    direction="next" onClickHandler={nextButton} />
            </Carousel>
        </>
    );
}

export default LandingTrustedCompaniesCarousel;