import React from "react";

function AboutUsIntro() {
  return (
    <>
      <div className="section about-us-intro d-flex justify-content-center align-items-center">
        <h1 className="font-weight-bold m-0">About Us</h1>
      </div>
    </>
  );
}

export default AboutUsIntro;
