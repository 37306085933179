import React from "react";

// reactstrap components
import {
  Container
} from "reactstrap";

// components
// import LandingTechnologyPartnersCarousel from './LandingTechnologyPartnersCarousel';

// img
import dupontLogo from "assets/img/roentgen/home/carousel/DUPONT.png";
import xylemLogo from "assets/img/roentgen/home/carousel/XYLEM 1.png";

function LandingTechnologyPartners() {
  return (
    <>
      <div className="section landing-technology-partners">
        <Container>
            <div className="text-center">
              <h4 className="mt-0 font-weight-bold">Our Technology Partners</h4>
              <div style={{ width: "50px", height: "10px", borderBottom: "3px solid", borderWidth: "medium" }}
                className="font-light-blue-color mx-auto mb-3 mt-1 active-div-tab" />
            </div>
            <div className="tech-partners align-items-center text-center mt-5">
              <img src={dupontLogo} alt="dupont-logo" className="mx-5 cursor-pointer" onClick={() => window.open("https://www.dupont.com/", "_blank", "noopener,noreferrer")} />
              <img src={xylemLogo} alt="xylem-logo" className="mx-5 cursor-pointer" onClick={() => window.open("https://www.xylem.com/en-us/", "_blank", "noopener,noreferrer")} />
            </div>
            {/* <LandingTechnologyPartnersCarousel /> */}
        </Container>
      </div>
    </>
  );
}

export default LandingTechnologyPartners;
