import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// img
import section4bg from "assets/img/roentgen/careers/section_4_img.png";

function SectionOne() {
  return (
    <>
      <div className="section-four">
        <Container>
          <div className="content">
            <Row className="large-screen-row align-items-center">
              <Col md={12} lg={6}>
                <div className="mx-5">
                  <h3 className="font-light-blue-color" style={{ fontWeight: 600 }}>Values</h3>
                  <p className="font-weight-normal mt-2">
                    The values of a firm set the tone for what matters most to them. It establishes a shared goal for all employees to comprehend,
                    achieve, and live by. Employees will comprehend the expected behaviors that will lead to success once their values have been defined
                    and promoted. Because our principles foster healthy relationships inside our organization, they help us stand out from other businesses in our field.
                    <br/><br/>
                    <strong>Integrity</strong> ― establishes the tone for the type of workplace culture you wish to establish.
                    <br/>
                    <strong>Reliability</strong> ― All of a company's components must be dependable in order for it to succeed.
                    <br/>
                    <strong>Self-improvement</strong> ― Making time for personal growth is an investment in both yourself and your company.
                    <br/>
                    <strong>Flexibility</strong> ― It gives employees a sense of worth also creating trust and a more creative working atmosphere.
                  </p>
                </div>
              </Col>
              <Col>
                <img src={section4bg} className="main-img" alt="section4-bg" />
              </Col>
            </Row>
            
            <Row className="small-screen-row d-none align-items-center">
              <Col md={12} lg={6} className="small-screen-img-column">
                <img src={section4bg} className="main-img" alt="section4-bg" />
              </Col>
              <Col>
                <div className="mx-5">
                  <h3 className="font-light-blue-color" style={{ fontWeight: 600 }}>Values</h3>
                  <p className="font-weight-normal mt-2">
                    The values of a firm set the tone for what matters most to them. It establishes a shared goal for all employees to comprehend,
                    achieve, and live by. Employees will comprehend the expected behaviors that will lead to success once their values have been defined
                    and promoted. Because our principles foster healthy relationships inside our organization, they help us stand out from other businesses in our field.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default SectionOne;
