import React from "react";

// reactstrap components
import {
  Nav,
  NavLink,
  NavItem
} from "reactstrap";

// index components
import TechnologyPartners from "./TechnologyPartners";
import Clients from "./Clients";

function ClientsDetails() {
  const [activeTab, setActiveTab] = React.useState(1);

  return (
    <>
      <div className="clients-details">
        <Nav tabs className="w-100">
          <NavItem>
            <NavLink
              className={activeTab === 1 && "active"}
              onClick={() => setActiveTab(1)}
            >
              Technology Partners
              {activeTab === 1 && <div style={{ width: "150px", borderBottom: "2px solid #4C84C3", borderWidth: "medium" }}
                className="mx-auto active-div-tab" />}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 2 && "active"}
              onClick={() => setActiveTab(2)}
            >
              Clients
              {activeTab === 2 && <div style={{ width: "60px", borderBottom: "2px solid #4C84C3", borderWidth: "medium" }}
                className="mx-auto active-div-tab" />}
            </NavLink>
          </NavItem>
        </Nav>
        {activeTab === 1 && <TechnologyPartners />}
        {activeTab === 2 && <Clients />}
      </div>
    </>
  );
}

export default ClientsDetails;
