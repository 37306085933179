import React from "react";

// reactstrap components
import {
  Nav,
  NavLink,
  NavItem
} from "reactstrap";

// index components
import AboutUsHistory from "./AboutUsHistory";
import AboutUsWhereWeAre from "./AboutUsWhereWeAre";
import AboutUsWhereWeAim from "./AboutUsWhereWeAim";
import AboutUsLeadership from "./AboutUsLeadership";
import AboutUsCompanyProfile from "./AboutUsCompanyProfile";

function AboutUsDescriptions() {
  const [activeTab, setActiveTab] = React.useState(1);

  return (
    <>
      <div className="about-us-description">
        <Nav tabs className="w-100">
          <NavItem>
            <NavLink
              className={activeTab === 1 && "active"}
              onClick={() => setActiveTab(1)}
            >
              Our History
              {activeTab === 1 && <div style={{ width: "90px", borderBottom: "1px solid #4C84C3", borderWidth: "medium" }}
                className="mx-auto active-div-tab" />}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 2 && "active"}
              onClick={() => setActiveTab(2)}
            >
              Where We Are Now
              {activeTab === 2 && <div style={{ width: "140px", borderBottom: "1px solid #4C84C3", borderWidth: "medium" }}
                className="mx-auto active-div-tab" />}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 3 && "active"}
              onClick={() => setActiveTab(3)}
            >
              What We Aim
              {activeTab === 3 && <div style={{ width: "110px", borderBottom: "1px solid #4C84C3", borderWidth: "medium" }}
                className="mx-auto active-div-tab" />}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 4 && "active"}
              onClick={() => setActiveTab(4)}
            >
              Leadership
              {activeTab === 4 && <div style={{ width: "85px", borderBottom: "1px solid #4C84C3", borderWidth: "medium" }}
                className="mx-auto active-div-tab" />}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 5 && "active"}
              onClick={() => setActiveTab(5)}
            >
              Company Profile
              {activeTab === 5 && <div style={{ width: "120px", borderBottom: "1px solid #4C84C3", borderWidth: "medium" }}
                className="mx-auto active-div-tab" />}
            </NavLink>
          </NavItem>
        </Nav>
        {activeTab === 1 && <AboutUsHistory />}
        {activeTab === 2 && <AboutUsWhereWeAre />}
        {activeTab === 3 && <AboutUsWhereWeAim />}
        {activeTab === 4 && <AboutUsLeadership />}
        {activeTab === 5 && <AboutUsCompanyProfile />}
      </div>
    </>
  );
}

export default AboutUsDescriptions;
