import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/roentgen.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
// pages
import LandingPage from "views/home/LandingPage.js";
import AboutUsPage from "views/about-us/AboutUsPage.js";
import ProductsAndServicesPage from "views/products-and-services/ProductsAndServicesPage.js";
import SustainabilityPage from "views/sustainability/SustainabilityPage.js";
import CareersPage from "views/careers/CareersPage.js";
import ClientsPage from "views/clients/ClientsPage.js";
import ContactUsPage from "views/contact-us/ContactUsPage";
// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route 
        path="/home"
        render={(props) => <LandingPage {...props} />}
      />
      <Route
        path="/about-us"
        render={(props) => <AboutUsPage {...props} />}
      />
      <Route
        path="/products-and-services"
        render={(props) => <ProductsAndServicesPage {...props} />}
      />
      <Route
        path="/sustainability"
        render={(props) => <SustainabilityPage {...props} />}
      />
      <Route
        path="/careers"
        render={(props) => <CareersPage {...props} />}
      />
      <Route
        path="/clients"
        render={(props) => <ClientsPage {...props} />}
      />
      <Route
        path="/contact"
        render={(props) => <ContactUsPage {...props} />}
      />
      <Redirect to="/home" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
