import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// img
import plant from "./../../assets/img/roentgen/home/plant.png";

function LandingIntro() {
  return (
    <>
      <div className="section landing-intro">
        <Container>
          <Row>
            <Col lg="4" sm="12" className="d-flex align-items-center justify-content-center">
              <img src={plant} alt="Roentgen" width={300}/>
            </Col>
            <Col lg="8" sm="12">
              <div className="intro mb-3 p-2" style={{ borderRadius: "15px" }}>
                <h3 className="font-weight-bold font-light-blue-color mb-3">Harmonizing Social Responsibility</h3>
                <p>
                  <strong> Roentgen Separation Technologie, (RST) Inc.</strong> is committed to upholding the highest standards of corporate social responsibility. We are focusing on improving
                  the quality of life by engaging in the communities, valuing the well-being of the employees, and promoting sustainable practices with the purpose of
                  providing value to our stakeholders with the best service while avoiding harm to the environment, society, or the economy. 
                </p>
              </div>
              <u><a href={"/sustainability"} className="font-light-blue-color text-underline">Learn more</a></u>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingIntro;
