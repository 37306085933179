import React from "react";

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText
} from "reactstrap";

// img
import waterTreatmentPlant from "assets/img/roentgen/products_and_services/WATER TREATMENT PLANT FINAL.png"
import ufSystem from "assets/img/roentgen/products_and_services/UF System Picture.jpg"
import roSystem from "assets/img/roentgen/products_and_services/RO system.jpg"
import mbrSystem from "assets/img/roentgen/products_and_services/MBR SYSTEM.jpg"

function PurificationSystems() {
  const cards = (item) => {
    return <Col sm={6} md={6} lg={6} xl={4} key={item.key} className='pb-3'>
        <Card className="shadow-sm h-100">
          <CardImg src={item.img} alt="Water Tubes" style={{ objectFit: 'cover', minHeight: '250px' }}/>
          <CardBody>
            <CardTitle tag="h6" className="default-card-text">
              {item.title}
            </CardTitle>
            <div className="card-img-overlay text-center">
              <CardTitle tag="h6" className="text-light">
                {item.title}
              </CardTitle>
              <CardText>
                {item.subTitle}
              </CardText>
            </div>
          </CardBody>
        </Card>
      </Col>;
  }

  const dummyItems = [
    {
        title: 'Water Treatment Plant',
        subTitle: '',
        img: waterTreatmentPlant,
        key: 3
    },
    {
        title: 'Ultra Filtration System (UF System)',
        subTitle: 'Ultrafiltration membrane filtration (UF) is a low pressure membrane process for water treatment that is designed to remove turbidity causing particles including those comprised of suspended solids, bacteria, colloidal matter and proteins.',
        img: ufSystem,
        key: 4
    },
    {
        title: 'Reverse Osmosis System (RO System)',
        subTitle: 'Reverse Osmosis is a water purification technology that uses a semipermeable membrane to remove ions, molecules and larger particles from drinking water.',
        img: roSystem,
        key: 5
    },
    {
        title: 'Membrane Bio Reactor (MBR System)',
        subTitle: 'Membrane bioreactor (MBR) is a combination of membrane processes like microfiltration or ultrafiltration with a biological wastewater treatment process, the activated sludge process. It is now widely used for municipal and industrial wastewater treatment.',
        img: mbrSystem,
        key: 6
    }
  ];

  return (
    <>
      <div className="pas-purifcation-systems">
        <Row>
          {dummyItems.map(item => {
            return cards(item)
          })}
        </Row>
      </div>
    </>
  );
}

export default PurificationSystems;
