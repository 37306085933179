import React from "react";

// boostrap
import {
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import SustainabilityCarousel from "./SustainabilityCarousel";

function SustainabilityPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <IndexNavbar isResponsive={false} />
      <div className="main sustainability">
        <Row>
          <Col xs={12} lg={12} xl={6} className="sustainability-img-column">
            <SustainabilityCarousel />
          </Col>
          <Col xs={12} lg={12} xl={6} className="sustainability-details-column">
            <div> 
              <h3 className="font-weight-bold font-light-blue-color">Sustainability</h3>
              <h1 className="font-weight-bold font-light-yellow-color mb-4 mt-2">Harmonizing Social Responsibility</h1>
              <p style={{ lineHeight: '26px', fontSize: '16px' }}>
                <strong> Roentgen Separation Technologie, (RST) Inc.</strong> is committed to upholding the highest standards of corporate social responsibility. We are focusing on improving
                the quality of life by engaging in the communities, valuing the well-being of the employees, and promoting sustainable practices with the purpose of
                providing value to our stakeholders with the best service while avoiding harm to the environment, society, or the economy. 
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default SustainabilityPage;
