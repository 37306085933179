import React from "react";

// boostrap
import {
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

// img
import locationLogo from "./../../assets/img/roentgen/contact/location_icon.png";
import emailLogo from "./../../assets/img/roentgen/contact/email_icon.png";
import phoneLogo from "./../../assets/img/roentgen/contact/phone_icon.png";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <IndexNavbar isResponsive={false} />
      <div className="main contact-us">
        <Row>
          <Col xs={12} lg={12} xl={6} className="contact-img-column">
            <h1 className="font-weight-bold">Get in touch with us.</h1>
            <p>Inquiry? Feedbacks? Send us a message. We would love to help!</p>
          </Col>
          <Col xs={12} lg={12} xl={6} className="contact-details-column">
            <div class="address mb-3">
              <Row>
                <Col xs={1}>
                  <img src={locationLogo} alt="address-icon" />
                </Col>
                <Col xs={11}>
                  <h5 className='font-weight-bold font-light-yellow-color'>Address</h5>
                  <p>(Metro Manila) Unit 2506 Entrata Urban Tower Condominium 2609 Civic Drive, Filinvest City Alabang, Muntinlupa City 1781 Philippines</p>
                  <p>(Cebu) 613 Robinland Business Center North Reclamation Area Zuellig Avenue Mandaue City Cebu, Philippines</p>
                </Col>
              </Row>
            </div>
            <div class="email mb-3">
              <Row>
                <Col xs={1}>
                  <img src={emailLogo} alt="email-icon" />
                </Col>
                <Col xs={11}>
                  <h5 className='font-weight-bold font-light-yellow-color'>Email</h5>
                  <a href="mailto: info@roentgenseparation.com" className="text-dark font-weight-normal email-address">info@roentgenseparation.com</a>
                </Col>
              </Row>
            </div>
            <div class="contact mb-3">
              <Row>
                <Col xs={1}>
                  <img src={phoneLogo} alt="phone-icon" />
                </Col>
                <Col xs={11}>
                  <h5 className='font-weight-bold font-light-yellow-color'>Contact Number</h5>
                  <p>+632 8 808 6471</p>
                  <p>+632 8 553 7101</p>
                  <p>+63 951 354 3838</p>
                </Col>
              </Row>
            </div>
            <div class="fax mb-3">
              <Row>
                <Col xs={1} />
                <Col xs={11}>
                  <h5 className='font-weight-bold font-light-yellow-color'>Fax</h5>
                  <p>+632 8 553 7101</p>
                </Col>
              </Row>
            </div>
            <div class="fax mb-3">
              <Row>
                <Col xs={1} />
                <Col xs={11}>
                  <h5 className='font-weight-bold font-light-yellow-color'>Give us a follow</h5>
                  <div class="social">
                    <a class="fa fa-linkedin" title="" rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/roentgen-separation-technologie-rst-inc"> </a>
                    <a class="fa fa-facebook" title="" rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/roentgenseparation/" > </a>
                    <a class="fa fa-twitter" title="" rel="noopener noreferrer" target="_blank" href="https://www.twitter.com" > </a>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default LandingPage;
