import React from "react";

import {
  Container
} from "reactstrap";

function JoinOurTeam() {
  return (
    <>
      <div className="join-our-team">
        <Container>
          <div className="content text-center">
            <h1 className="font-light-yellow-color mb-3" style={{ fontWeight: 'bold' }}>Join our Team!</h1>
            <p className="text-center font-weight-normal mb-0">Send your resume at:</p>
            <a href="mailto: info@roentgenseparation.com" className="font-light-yellow-color font-weight-normal email-address">info@roentgenseparation.com</a>
          </div>
        </Container>
      </div>
    </>
  );
}

export default JoinOurTeam;
