import React from "react";

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";

// img
import filterPress from "assets/img/roentgen/products_and_services/Filter Press.png"
import screwPress from "assets/img/roentgen/products_and_services/Screw Press.jpg"

function DewateringSystems() {
  const cards = (item) => {
    return <Col sm={6} md={6} lg={6} xl={4} key={item.key} className='pb-3'>
        <Card className="shadow-sm h-100">
          <CardImg width="100%" src={item.img} alt="Water Tubes" style={{ objectFit: 'cover', minHeight: '250px' }} />
          <CardBody>
            <CardTitle tag="h6" className="default-card-text">
              {item.title}
            </CardTitle>
            <div className="card-img-overlay text-center">
              <CardTitle tag="h6" className="text-light">
                {item.title}
              </CardTitle>
              <CardText>
                {item.subTitle}
              </CardText>
            </div>
          </CardBody>
        </Card>
      </Col>;
  }

  const dummyItems = [
    {
        title: 'Filter Press',
        subTitle: '',
        img: filterPress,
        key: 1
    },
    {
        title: 'Screw Press',
        subTitle: '',
        img: screwPress,
        key: 2
    }
  ];

  return (
    <>
      <div className="pas-dewatering-systems">
        <Row>
          {dummyItems.map(item => {
            return cards(item)
          })}
        </Row>
      </div>
    </>
  );
}

export default DewateringSystems;
