/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Col, Container } from "reactstrap";

// img
import roentgenLogo from "./../../assets/img/roentgen/roentgen_white_long.png";
import locationLogo from "./../../assets/img/roentgen/home/footer/location_icon.png";
import emailLogo from "./../../assets/img/roentgen/home/footer/email_icon.png";
import phoneLogo from "./../../assets/img/roentgen/home/footer/phone_icon.png";

function LandingPageFooter() {
  return (
    <footer className="footer">
      <Container>
        <Row className='pt-5'>
          <Col lg={6} sm={12}>
            <img src={roentgenLogo} alt="Roentgen Icon" width={350} style={{ marginLeft: "-10px" }} />
            <div class="feedback">
                <h3 className='font-weight-bold mb-1'>Get in touch with us.</h3>
                <p>Inquiry? Feedbacks? Send us a message!</p>
            </div>
            <div class="social mb-4">
                <h4 className='font-weight-bold mb-1'>Give us a follow</h4>
                <a class="fa fa-linkedin mr-3" title="" target="_blank" href="https://www.linkedin.com/company/roentgen-separation-technologie-rst-inc"></a>
                <a class="fa fa-facebook mr-3" title="" target="_blank" href="https://www.facebook.com/roentgenseparation/"></a>
                <a class="fa fa-twitter" title="" target="_blank" href="https://www.twitter.com"></a>
            </div>
          </Col>
          <Col lg={6} sm={12}>
            <div class="address mb-3">
                <Row>
                  <Col xs={1}>
                    <img src={locationLogo} alt="address-icon" style={{ marginTop: "-10px" }} />
                  </Col>
                  <Col xs={11}>
                    <h5 className='font-weight-bold'>Address</h5>
                    <p>(Metro Manila) Unit 2506 Entrata Urban Tower Condominium 2609 Civic Drive, Filinvest City Alabang, Muntinlupa City 1781 Philippines</p>
                    <p>(Cebu) 613 Robinland Business Center North Reclamation Area Zuellig Avenue Mandaue City Cebu, Philippines</p>
                  </Col>
                </Row>
            </div>
            <div class="email mb-3">
                <Row>
                  <Col xs={1}>
                    <img src={emailLogo} alt="email-icon" style={{ marginTop: "-10px" }} />
                  </Col>
                  <Col xs={11}>
                    <h5 className='font-weight-bold'>Email</h5>
                    <a href="mailto: info@roentgenseparation.com" className="text-light font-weight-normal email-address">info@roentgenseparation.com</a>
                  </Col>
                </Row>
            </div>
            <div class="contact">
                <Row>
                  <Col xs={1}>
                    <img src={phoneLogo} alt="phone-icon" style={{ marginTop: "-10px" }} />
                  </Col>
                  <Col xs={11}>
                    <h5 className='font-weight-bold'>Contact Number</h5>
                    <p>+632 8 808 6471</p>
                    <p>+632 8 553 7101</p>
                    <p>+63 951 354 3838</p>
                  </Col>
                </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="credits mx-auto mt-3">
            <span className="copyright">
              All Rights Reserved © {new Date().getFullYear()}, Roentgen Separation Technologie, (RST) Inc.
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default LandingPageFooter;
