import React from "react";

// reactstrap components
import {
  Row,
  Col,
  Container
} from "reactstrap";

// components
import LandingLatestNewsCarousel from './LandingLatestNewsCarousel';

function LandingLatestNews() {
  return (
    <>
      <div className="section landing-latest-news">
        <Container>
          <Row>
            <Col lg={3} xs={12} className="d-flex align-items-center" style={{ flexWrap: "wrap"}}>
              <div>
                <h4 className="font-weight-bold font-light-blue-color mt-0 mb-3">Products & Services</h4>
              </div>
            </Col>
            <Col lg={9} xs={12}>
              <LandingLatestNewsCarousel />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingLatestNews;
