import React from "react";

// reactstrap components
import {
  Container,
  Card,
  Row,
  Col
} from "reactstrap";

// img
import dupontLogo from "./../../assets/img/roentgen/clients/DUPONT.png";
import xylemLogo from "./../../assets/img/roentgen/clients/XYLEM 1.png";

function TechnologyPartners() {
  const techPartners = [
    {
      image: dupontLogo,
      title: "Dupont",
      subTitle: "",
      website: "https://www.dupont.com/"
    },
    {
      image: xylemLogo,
      title: "Xylem Inc.",
      subTitle: "",
      website: "https://www.xylem.com/en-us/"
    }
  ];

  const techPartnersCards = (item) => {
    return <Col sm={12} md={6} key={item.title}>
        <Card body className="py-5 my-5 cursor-pointer" onClick={() => window.open(item.website, "_blank", "noopener,noreferrer")}>
          <Row className="d-flex align-items-center justify-content-center">
            <img src={item.image} alt={`${item.title}-icon`} style={{ height: "80px", width: "auto"}} />
          </Row>
        </Card>
      </Col>;
  }

  return (
    <>
      <div className="section clients-tech-partners">
        <Container>
          <div className="content">
            <Row className="justify-content-center">
              {techPartners.map(item => {
                return techPartnersCards(item)
              })}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default TechnologyPartners;
