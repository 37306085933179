import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
import roentgenWhiteLogo from "./../../assets/img/roentgen/roentgen_white_long.png";
import roentgenBlackLogo from "./../../assets/img/roentgen/roentgen_black_long.png";

function IndexNavbar({
  isResponsive = false,
  navbarShadow = true
}) {
  const [navbarColor, setNavbarColor] = React.useState(isResponsive ? "navbar-transparent" : "");
  const [navbarLogo, setNavbarLogo] = React.useState(isResponsive ? roentgenWhiteLogo : roentgenBlackLogo);
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  const currentPath = window.location.pathname;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
        setNavbarLogo(roentgenBlackLogo);
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
        setNavbarLogo(roentgenWhiteLogo);
      }
    };

    if (isResponsive) window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      if (isResponsive) window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames(isResponsive ? "fixed-top" : "", navbarColor, !navbarShadow && "hide-navbar-shadow")} expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            href="/home"
          >
            <img src={navbarLogo} alt="Roentgen" width={200}/>
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              {currentPath === '/home' ?
                <Button
                  className="btn-round"
                  color="warning"
                  href="/home"
                >
                  Home
                </Button> :
                <NavLink
                  data-placement="bottom"
                  href="/home"
                  title="Home"
                >
                  Home
                </NavLink>
              }
            </NavItem>
            <NavItem>
              {currentPath === '/about-us' ?
                <Button
                  className="btn-round"
                  color="warning"
                  href="/about-us"
                >
                  About Us
                </Button> :
                <NavLink
                  data-placement="bottom"
                  href="/about-us"
                  title="About Us"
                >
                  About Us
                </NavLink>
              }
            </NavItem>
            <NavItem>
              {currentPath.includes('/products-and-services') ?
                <Button
                  className="btn-round"
                  color="warning"
                  href="/products-and-services"
                >
                  Products & Services
                </Button> :
                <NavLink
                  data-placement="bottom"
                  href="/products-and-services"
                  title="Products & Services"
                >
                  Products & Services
                </NavLink>
              }
            </NavItem>
            <NavItem>
              {currentPath === '/sustainability' ?
                <Button
                  className="btn-round"
                  color="warning"
                  href="/sustainability"
                >
                  Sustainability
                </Button> :
                <NavLink
                  data-placement="bottom"
                  href="/sustainability"
                  title="Sustainability"
                >
                  Sustainability
                </NavLink>
              }
            </NavItem>
            <NavItem>
              {currentPath === '/careers' ?
                <Button
                  className="btn-round"
                  color="warning"
                  href="/careers"
                >
                  Careers
                </Button> :
                <NavLink
                  data-placement="bottom"
                  href="/careers"
                  title="Careers"
                >
                  Careers
                </NavLink>
              }
            </NavItem>
            <NavItem>
              {currentPath === '/clients' ?
                <Button
                  className="btn-round"
                  color="warning"
                  href="/clients"
                >
                  Business Partners
                </Button> :
                <NavLink
                  data-placement="bottom"
                  href="/clients"
                  title="Clients"
                >
                  Business Partners
                </NavLink>
              }
            </NavItem>
            <NavItem>
              {currentPath === '/contact' ?
                <Button
                  className="btn-round"
                  color="warning"
                  href="/contact"
                >
                  Contact Us
                </Button> :
                <NavLink
                  data-placement="bottom"
                  href="/contact"
                  title="Contact Us"
                >
                  Contact Us
                </NavLink>
              }
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
