import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

// index sections
import ClientsIntro from "./ClientsIntro";
import ClientsDetails from "./ClientsDetails";

function ClientsPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <IndexNavbar isResponsive={false} />
      <ClientsIntro />
      <div className="main">
        <ClientsDetails />
      </div>
      <Footer />
    </>
  );
}

export default ClientsPage;
