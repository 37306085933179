import React from "react";

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Button
} from "reactstrap";

// img
import sampleImg from "assets/img/roentgen/products_and_services/DRUM.png"
import sacksImg from "assets/img/roentgen/products_and_services/with sacks.jpg"
import containersImg from "assets/img/roentgen/products_and_services/containers.png"

function Chemicals() {
  const cards = (item) => {
    return <Col xs={12} sm={6} md={4} key={item.key}>
        <Card className="shadow-sm">
          <CardImg width="100%" src={sampleImg} alt="Water Tubes" />
          <CardBody>
            <CardTitle tag="h6" className="default-card-text">
              {item.title}
            </CardTitle>
            <div className="card-img-overlay text-center">
              <CardTitle tag="h6" className="text-light">
                {item.title}
              </CardTitle>
              <CardText>
                {item.subTitle}
              </CardText>
            </div>
          </CardBody>
        </Card>
      </Col>;
  };

  const chemicals = [
    {
        title: 'Coagulants (PAC, Ferric Chloride, ACH)',
        subTitle: '',
        key: 0
    },
    {
        title: 'Flocculants (Anionic, Cationic, Emulsion)',
        subTitle: '',
        key: 1
    },
    {
        title: 'Antifoam/Defoamer (TF, Food Grade)',
        subTitle: '',
        key: 2
    },
    {
        title: 'Biocides (RO, Cooling Systems, SEAWATER)',
        subTitle: '',
        key: 3
    },
    {
        title: 'Heavy Metal Precipitants',
        subTitle: '',
        key: 4
    },
    {
        title: 'Enzymes/Inoculant',
        subTitle: '',
        key: 5
    },
    {
        title: 'Odor Eliminator',
        subTitle: '',
        key: 6
    },
    {
        title: 'Hydrated Lime',
        subTitle: '',
        key: 7
    },
    {
        title: 'Sodium Metabisulfite',
        subTitle: '',
        key: 8
    },
    {
        title: 'Sulfamic Acid',
        subTitle: '',
        key: 9
    },
    {
        title: 'Aluminum Sulfate',
        subTitle: '',
        key: 10
    },
    {
        title: 'Phosphoric Acid',
        subTitle: '',
        key: 11
    },
    {
        title: 'Filter Aid/Diatomaceous Earth',
        subTitle: '',
        key: 12
    },
    {
        title: 'Hydrochloric Acid 30-32%',
        subTitle: '',
        key: 13
    },
    {
        title: 'Sodium Hydroxide (Caustic Soda) – Liquid, Flakes, Pearl',
        subTitle: '',
        key: 14
    },
    {
        title: 'Sodium Hypochlorite',
        subTitle: '',
        key: 15
    }
];

  return (
    <>
      <Row>
        <Col className='px-1' sm={12} md={6}>
          <img src={containersImg} alt='containers-bg' className='h-100 w-100' style={{ objectFit: 'cover' }} />
        </Col>
        <Col className='px-1' sm={12} md={6}>
          <img src={sacksImg} alt='sacks-bg' className='h-100 w-100' style={{ objectFit: 'cover' }} />
        </Col>
      </Row>
      <div className="section products-and-services-chemicals pt-2">
        <div className='w-100 text-center pb-4'>
          <h1 className='font-weight-bold mb-5'>CHEMICALS</h1>
          <div className='mx-auto' style={{ borderBottom: '2px solid black', width: '75%' }} />
        </div>
        <Container>
          <div className="content text-center">
              {chemicals.map(item => {
                return <h3 className='font-weight-normal'>{item.title}</h3>
              })}
          </div>
        </Container>
        <Button className='inquire-button font-weight-bold' href="/contact">
          <i class="fa fa-phone mr-1" />
          <span><u>Inquire now</u></span>
        </Button>
      </div>
    </>
  );
}

export default Chemicals;
