import React from "react";

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";

// img
import sampleImg from "assets/img/roentgen/products_and_services/water_tubes_img.png"

function PurificationSystems() {
  const cards = (item) => {
    return <Col xs={12} sm={6} md={4} key={item.key}>
        <Card className="shadow-sm">
          <CardImg width="100%" src={sampleImg} alt="Water Tubes" />
          <CardBody>
            <CardTitle tag="h6" className="default-card-text">
              {item.title}
            </CardTitle>
            <div className="card-img-overlay text-center">
              <CardTitle tag="h6" className="text-light">
                {item.title}
              </CardTitle>
              <CardText>
                {item.subTitle}
              </CardText>
            </div>
          </CardBody>
        </Card>
      </Col>;
  }

  const dummyItems = [
    {
        title: 'Trommel Screens',
        subTitle: '',
        key: 1
    },
    {
        title: 'DSM Screens',
        subTitle: '',
        key: 2
    },
    {
        title: 'Clarified Juice Screens',
        subTitle: '',
        key: 3
    },
    {
        title: 'Laterals & Mushroom Strainers',
        subTitle: '',
        key: 4
    },
    {
        title: 'Centrifugal Basket Screens',
        subTitle: '',
        key: 5
    }
  ];

  return (
    <>
      <div className="pas-screen">
        <Row>
          {dummyItems.map(item => {
            return cards(item)
          })}
        </Row>
      </div>
    </>
  );
}

export default PurificationSystems;
