import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// img
import section3bg from "assets/img/roentgen/careers/section_3_img.png";

function SectionFour() {
  return (
    <>
      <div className="section-three">
        <Container>
          <div className="content">
            <Row className="align-items-center">
              <Col>
                <img src={section3bg} className="main-img" alt="section3-bg" />
              </Col>
              <Col md={12} lg={6}>
                <div className="mx-5">
                  <h3 className="font-light-blue-color text-right" style={{ fontWeight: 600 }}>Enhance our employee's worth</h3>
                  <p className="font-weight-normal mt-2">
                    RSTI is a corporation that values its employees' contributions. We keep our employees by offering new learning opportunities and
                    encouraging them to broaden their skill sets. We encourage and compensate our employees to participate in new learning opportunities
                    that will not only teach them new skills to help them do their jobs better, but also help them grow as employees and advance within the company.
                    Supporting their ambition and drive, as well as just rewarding their achievements within the firm, are wonderful ways to build the kind of employee
                    loyalty that any company desires.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default SectionFour;
